import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 特典情報の一覧
    settingPrivileges: []
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object[]} 特典情報の一覧
   */
  settingPrivileges: state => state.settingPrivileges
}

const mutations = {
  /**
   * 特典情報の一覧をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object[]} settingPrivileges 特典情報の一覧
   */
  setSettingPrivileges: (state, settingPrivileges) => {
    state.settingPrivileges = settingPrivileges
  },
  /**
   * 特典情報の一覧をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} settingPrivilege 特典情報
   */
  setSettingPrivilege: (state, settingPrivilege) => {
    const index = state.settingPrivileges.findIndex(privilege => privilege.spid === settingPrivilege.spid)
    if (index > -1) {
      // 既に特典情報がある場合は更新
      Object.keys(settingPrivilege).forEach(key => {
        state.settingPrivileges[index][key] = settingPrivilege[key]
      })
    } else {
      // 新規の場合追加
      state.settingPrivileges.push(settingPrivilege)
    }
  },
  /**
   * 特典情報をstateから削除
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} spid 削除する特典情報のID
   */
  deleteSettingPrivilege: (state, spid) => {
    state.settingPrivileges = state.settingPrivileges.filter(privilege => privilege.spid !== spid)
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 特典情報の一覧を取得
   * @return {void}
   */
  getSettingPrivileges: async ({ commit }) => {
    try {
      const snapshot = await firestore
        .collection('setting_privileges')
        .orderBy('rank', 'asc')
        .get()
      const settingPrivileges = []
      snapshot.forEach(doc => {
        settingPrivileges.push(Object.assign(doc.data(), { spid: doc.id }))
      })
      commit('setSettingPrivileges', settingPrivileges)
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 特典情報作成
   * @param {Object} params 引数
   */
  addSettingPrivilege: async ({ commit }, params) => {
    try {
      const ref = await firestore.collection('setting_privileges').add(params)
      commit('setSettingPrivilege', Object.assign(params, { spid: ref.id }))
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 特典情報の更新
   * @param {Object} payload 引数
   * @param {String} payload.spid 更新する特典情報のID
   * @param {Object} payload.params 更新する特典情報
   */
  updateSettingPrivilege: async ({ commit }, payload) => {
    try {
      await firestore.collection('setting_privileges').doc(payload.spid).update(payload.params)
      commit('setSettingPrivilege', Object.assign(payload.params, { spid: payload.spid }))
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 特典情報の削除
   * @param {String} spid 削除する特典情報のID
   */
  deleteSettingPrivilege: async ({ commit }, spid) => {
    try {
      await firestore.collection('setting_privileges').doc(spid).delete()
      commit('deleteSettingPrivilege', spid)
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

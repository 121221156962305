import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // FAQの一覧（rank順）
    faqs: []
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} FAQの一覧（rank順）
   */
  faqs: state => state.faqs
}

const mutations = {
  /**
   * FAQ情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} newFaq FAQのオブジェクト情報
   */
  setFaq: (state, newFaq) => {
    const index = state.faqs.findIndex(faq => faq.faqid === newFaq.faqid)
    if (index > -1) {
      // 既にFAQ情報がある場合は更新
      Object.keys(newFaq).forEach(key => {
        state.faqs[index][key] = newFaq[key]
      })
    } else {
      // 新規追加の場合
      state.faqs.push(newFaq)
    }
  },
  /**
   * FAQをstateから削除
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} faqid 削除するFAQのID
   */
  deleteFaq: (state, faqid) => {
    state.faqs = state.faqs.filter(faq => faq.faqid !== faqid)
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * FAQの一覧を取得
   */
  getFaqs: async ({ commit }) => {
    try {
      const snapshot = await firestore
        .collection('faqs')
        .orderBy('rank', 'asc')
        .get()

      // 取得した値をstoreに格納
      snapshot.forEach(doc => commit('setFaq', Object.assign({ faqid: doc.id }, doc.data())))
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * FAQの作成
   * @param {Object} params FAQ情報
   */
  addFaq: async ({ commit }, params) => {
    try {
      const ref = await firestore.collection('faqs').add(params)
      commit('setFaq', Object.assign(params, { faqid: ref.id }))
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * FAQの更新
   * @param {String} faqid 更新するFAQ情報のID
   * @param {Object} params 更新するFAQ情報
   */
  updateFaq: async ({ commit }, { faqid, params }) => {
    try {
      await firestore.collection('faqs').doc(faqid).update(params)
      commit('setFaq', Object.assign(params, { faqid: faqid }))
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * FAQの削除
   * @param {String} faqid 削除するFAQのID
   */
  deleteFaq: async ({ commit }, faqid) => {
    try {
      await firestore.collection('faqs').doc(faqid).delete()
      commit('deleteFaq', faqid)
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // { lrid: '', domains: [] }
    referrer: {}
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {String} ドキュメントのid
   */
  lrid: state => state.referrer.lrid ? state.referrer.lrid : '',
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {String[]} 許可されたドメインの一覧
   */
  domains: state => state.referrer.domains ? state.referrer.domains : []
}

const mutations = {
  /**
   * 許可されたreferrerをstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} referrer 許可されたreferrerの一覧
   */
  setReferrer: (state, referrer) => {
    state.referrer = Object.assign({}, referrer)
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 許可されたreferrerのdomain一覧を取得する
   */
  getLoginReferrer: async ({ commit }) => {
    try {
      const snapshot = await firestore
        .collection('login_referrer')
        .orderBy('updatedAt', 'desc')
        .limit(1)
        .get()

      const doc = snapshot.empty ? null : snapshot.docs[0]
      if (doc) commit('setReferrer', Object.assign(doc.data(), { lrid: doc.id }))
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * referrer情報を追加する
   * @param {Object} params 新規で追加するreferrer情報
   */
  addLoginReferrer: async ({ commit }, params) => {
    try {
      const ref = await firestore.collection('login_referrer').add(params)

      commit('setReferrer', Object.assign(params, { lrid: ref.id }))
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * referrer情報を更新する
   * @param {Object} payload 更新対象
   * @param {String} payload.lrid 更新対象のドキュメントID
   * @param {Object} payload.params 更新するreferrer情報
   */
  updateLoginReferrer: async ({ commit }, payload) => {
    try {
      await firestore.collection('login_referrer').doc(payload.lrid).update(payload.params)

      commit('setReferrer', Object.assign(payload.params, { lrid: payload.sbid }))
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

<template>
  <div class="menu-original" @click="action()">
    <h2 class="menu-original__title">{{ item.title }}</h2>
    <p class="menu-original__content">{{ item.content }}</p>
  </div>
</template>

<script>
export default {
  name: 'menu-original',
  props: {
    /**
     * 表示する内容を記載
     * @param {String} item.title タイトル
     * @param {String} item.content 説明文
     * @param {String} item.to 遷移先の名前
     * @param {Function} item.func 押下時に実行する関数
     */
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    /**
     * 指定内容に応じた処理
     * item.toが存在する場合は、画面遷移
     * item.funcが存在する場合は、関数の実行
     */
    action () {
      this.item.to ? this.$router.push({ name: this.item.to }) : this.item.func()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

.menu-original {
  width: 100vw;
  max-width: $max_width;
  padding: $padding_height $padding_width;
  margin-left: -$padding_width;
  border-bottom: 1px solid $gray_color;
  &__title {
    margin: 0;
    font-size: 1.4rem;
  }
  &__content {
    padding: 0 5px;
    margin: 10px 0 0;
    font-size: 1.2rem;
    color: $gray_darken_color;
  }
}
</style>

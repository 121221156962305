<template>
  <header class="header" v-if="isDisplay">
    <v-btn text class="header__btn" v-if="type.includes('back')" @click="back()">
      <v-icon class="header__btn__icon">arrow_back</v-icon>
    </v-btn>
    <v-img class="header__logo" v-if="type.includes('logo')" :src="require('@/assets/img/logo.svg')" contain />
    <p class="header__title" v-if="type.includes('title')">{{ title }}</p>
  </header>
</template>

<script>
// ヘッダーを表示させないページ名
const NOT_DISPLAY = [
  'login',
  'error',
  'notfound'
]

// ヘッダーの詳細
// type: back, logo, title
const HEADER_DETAIL = {
  home: {
    type: ['title'],
    title: '管理者ページ'
  },
  admin: {
    type: ['back', 'title'],
    title: '管理者権限'
  },
  admin_add: {
    type: ['back', 'title'],
    title: '管理者権限の追加'
  },
  admin_delete: {
    type: ['back', 'title'],
    title: '管理者権限の削除'
  },
  member: {
    type: ['back', 'title'],
    title: '会員情報'
  },
  member_new: {
    type: ['back', 'title'],
    title: '新規ユーザー'
  },
  member_cancel: {
    type: ['back', 'title'],
    title: '退会済みユーザー'
  },
  member_list: {
    type: ['back', 'title'],
    title: 'ユーザー一覧'
  },
  exception: {
    type: ['back', 'title'],
    title: '例外ユーザーの設定'
  },
  exception_add: {
    type: ['back', 'title'],
    title: '例外ユーザーの追加'
  },
  exception_edit: {
    type: ['back', 'title'],
    title: '例外ユーザーの編集・削除'
  },
  exception_tag: {
    type: ['back', 'title'],
    title: '例外ユーザーのタグ管理'
  },
  invitation: {
    type: ['back', 'title'],
    title: '招待コード'
  },
  invitation_setting: {
    type: ['back', 'title'],
    title: '招待コードの設定'
  },
  invitation_create: {
    type: ['back', 'title'],
    title: '招待コードの発行'
  },
  invitation_clients: {
    type: ['back', 'title'],
    title: '招待コードの参照'
  },
  invitation_clients_detail: {
    type: ['back', 'title'],
    title: '招待コードの参照'
  },
  group: {
    type: ['back', 'title'],
    title: '会員限定グループ'
  },
  group_setting: {
    type: ['back', 'title'],
    title: '会員限定グループの設定'
  },
  ticket: {
    type: ['back', 'title'],
    title: '回数券'
  },
  ticket_setting: {
    type: ['back', 'title'],
    title: '回数券の設定'
  },
  ticket_histories: {
    type: ['back', 'title'],
    title: '回数券の利用履歴'
  },
  setting: {
    type: ['back', 'title'],
    title: '設定'
  },
  setting_general: {
    type: ['back', 'title'],
    title: '基本情報'
  },
  setting_signup: {
    type: ['back', 'title'],
    title: 'ご入会の案内'
  },
  setting_registrator: {
    type: ['back', 'title'],
    title: '入会ボタン'
  },
  setting_representative: {
    type: ['back', 'title'],
    title: '代表者情報'
  },
  setting_movie: {
    type: ['back', 'title'],
    title: 'メッセージ動画'
  },
  setting_display: {
    type: ['back', 'title'],
    title: '表示内容'
  },
  setting_image: {
    type: ['back', 'title'],
    title: '画像情報'
  },
  setting_privilege: {
    type: ['back', 'title'],
    title: '入会特典'
  },
  setting_faq: {
    type: ['back', 'title'],
    title: 'よくある質問（FAQ）'
  },
  setting_freetrial: {
    type: ['back', 'title'],
    title: '無料トライアル'
  },
  setting_login: {
    type: ['back', 'title'],
    title: '認証ドメイン設定'
  },
  setting_footer: {
    type: ['back', 'title'],
    title: 'フッター'
  },
  request: {
    type: ['title'],
    title: '管理者の初回登録'
  }
}

export default {
  computed: {
    /**
     * @return {Boolean} ヘッダーを表示させるかどうか
     */
    isDisplay () {
      return !NOT_DISPLAY.includes(this.$route.name)
    },
    /**
     * @return {String} router.jsで定義された名前
     */
    pageName () {
      return this.$route.name
    },
    /**
     * @return {String} ヘッダーのタイトル
     */
    title () {
      return this.pageName && HEADER_DETAIL[this.pageName].title ? HEADER_DETAIL[this.pageName].title : ''
    },
    /**
     * @return {String} ヘッダーのタイプ
     */
    type () {
      return this.pageName && HEADER_DETAIL[this.pageName].type ? HEADER_DETAIL[this.pageName].type : ''
    }
  },
  methods: {
    /**
     * 前のページに戻る
     * 直リンクなどで前のページが存在しない場合はホームへ遷移させる
     */
    back () {
      if (window.history.length > 2) {
        this.$router.go(-1)
      } else {
        this.$router.push({ name: 'home' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.header {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: $header_zindex;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  max-width: $max_width;
  height: $header_height;
  text-align: center;
  background-color: $white_color;
  transform: translate(-50%, 0);
  &__btn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10px;
    margin: auto;
    &.v-btn:not(.v-btn--round).v-size--default {
      min-width: auto;
      height: 20px;
      padding: 0;
      color: $black_color;
    }
    &__icon {
      width: 20px;
      height: 20px;
      &.v-icon {
        font-size: 2rem;
      }
    }
  }
  &__logo {
    width: 120px;
    max-height: calc(#{$header_height} - 10px);
    margin: 0 auto;
  }
  &__title {
    margin: 0;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
}
</style>

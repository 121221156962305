import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // サービスが初回公開前かどうか
    isFirstPublished: false,
    // 最新の停止情報
    stop: null
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Boolean} サービスが初回公開前かどうか
   */
  isFirstPublished: state => state.isFirstPublished,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 最新の停止情報
   */
  stop: state => state.stop
}

const mutations = {
  /**
   * 初回公開前有無をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Boolean} isFirstPublished 初回公開前有無
   */
  setFirstPublished: (state, isFirstPublished) => {
    state.isFirstPublished = isFirstPublished
  },
  /**
   * 停止情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} stop 停止情報
   */
  setStop: (state, stop) => {
    state.stop = stop
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}
const actions = {
  /**
   * サービスが初回公開前かどうか
   * 初回納品時は手動でstopsにendAtがnullな情報が一つ格納されている
   * @return {Boolean} サービスが初回公開前かどうか
   */
  isFirstPublished: async ({ commit }) => {
    try {
      const snapshot = await firestore
        .collection('stops')
        .get()

      const doc = snapshot.docs[0]
      const isFirstPublished = snapshot.size === 1 && !doc.data().endAt
      commit('setFirstPublished', isFirstPublished)
      if (isFirstPublished) commit('setStop', Object.assign({ stid: doc.id }, doc.data()))
      return isFirstPublished
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 停止情報の更新
   * @param {Object} payload 引数の情報
   * @param {String} stid 停止情報のドキュメントID
   * @param {Object} params 更新する値
   * @return {void}
   */
  updateStop: async ({ commit }, { stid, params }) => {
    try {
      await firestore
        .collection('stops')
        .doc(stid)
        .update(params)

      // 一度リセットして再度新しく取得させる
      commit('setStop', null)
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 例外情報
    exemptions: []
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object[]} 例外情報一覧
   */
  exemptions: state => state.exemptions
}

const mutations = {
  /**
   * 例外情報一覧をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object[]} exemptions 例外情報一覧
   */
  setExemptions: (state, exemptions) => {
    state.exemptions = exemptions
  },
  /**
   * 例外情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} newExemption 例外情報
   */
  setExemption: (state, newExemption) => {
    state.exemptions.find(exemption => exemption.eid === newExemption.eid) ?
      state.exemptions = state.exemptions.map(exemption => exemption.eid === newExemption.eid ? newExemption : exemption) :
      state.exemptions.push(newExemption)
  },
  /**
   * stateの例外情報を更新
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} updateExemption 例外情報の更新内容
   */
  updateExemption: (state, updateExemption) =>{
    const index = state.exemptions.findIndex(exemption => exemption.eid === updateExemption.eid)

    Object.keys(updateExemption).forEach(key => {
      state.exemptions[index][key] = updateExemption[key]
    })
  },
  /**
   * 例外情報の削除
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} eid 例外情報のID
   */
  deleteExemption: (state, eid) => {
    state.exemptions = state.exemptions.filter(exemption => exemption.eid !== eid)
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 指定uidの例外情報を取得
   * @param {String} uid ユーザーID
   * @return {String} 指定uidの例外情報のID、存在しない場合null
   */
  getExemption: async ({ commit }, uid) => {
    try {
      const snapshot = await firestore
        .collection('exemptions')
        .where('uid', '==', uid)
        .get()
      const exemption = snapshot.size === 1 ? Object.assign(snapshot.docs[0].data(), { eid: snapshot.docs[0].id }) : null
      return exemption ? exemption.eid : null
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 例外情報の取得
   */
  getExemptions: async ({ commit }) => {
    try {
      const snapshot = await firestore
        .collection('exemptions')
        .get()
      const exemptions = []
      snapshot.forEach(doc => {
        exemptions.push(Object.assign(doc.data(), { eid: doc.id }))
      })
      commit('setExemptions', exemptions)
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 例外情報の作成
   * @param {Object} params 引数
   * @return {String} 例外情報のドキュメントID
   */
  addExemption: async ({ commit, getters }, params) => {
    try {
      const ref = await firestore.collection('exemptions').add(params)
      // 例外情報一覧を取得済みであればstateに追加した例外情報をセットする
      if (getters.exemptions.length !== 0) commit('setExemption', Object.assign(params, { eid: ref.id }))
      return ref.id
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 例外情報の編集
   * @param {Object} payload 引数
   * @param {String} payload.eid 例外情報のドキュメントID
   * @param {Object} payload.params 更新内容
   */
  updateExemptions: async ({ commit }, payload) => {
    try {
      await firestore.collection('exemptions')
        .doc(payload.eid)
        .update(payload.params)
      commit('updateExemption', Object.assign(payload.params, { eid: payload.eid }))
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 例外情報の削除
   * @param {String} eid 例外情報のID
   */
  deleteExemption: async ({ commit }, eid) => {
    try {
      await firestore
        .collection('exemptions')
        .doc(eid)
        .delete()
      commit('deleteExemption', eid)
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // { `invitaionCodeの値`: uid, `invitaionCodeの値`: uid, ... }
    histories: {}
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} invitationCode 招待コード
   * @return {String} 利用者のUID
   */
  historyUID: state => invitataionCode => state.histories[invitataionCode] ? state.histories[invitataionCode] : null
}

const mutations = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload 追加する利用履歴 { `invitaionCodeの値`: uid }
   */
  setHistory (state, payload) {
    state.histories = Object.assign({}, state.histories, payload)
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 招待コードから利用履歴を取得する
   * @param {String} invitationCode 招待コード
   */
  async getHistory ({ commit }, invitationCode) {
    try {
      const snapshot = await firestore
        .collection('invitation_histories')
        .where('code', '==', invitationCode)
        .limit(1)
        .get()
      const doc = snapshot.empty ? null : snapshot.docs[0]
      if (doc) commit('setHistory', { [doc.data().code]: doc.data().uid })
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 発行済の全ての回数券情報
    tickets: {},
    // 利用済みの回数券情報（利用日順）
    usedTickets: []
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 発行済の全ての回数券情報
   */
  tickets: state => state.tickets,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object[]} 利用済みの回数券情報（利用日順）
   */
  usedTickets: state => state.usedTickets
}

const mutations = {
  /**
   * 回数券情報のセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} tiid 回数券情報のドキュメントID
   * @param {Object} ticket 回数券のオブジェクト情報
   */
  setTicket: (state, { tiid, ticket }) => {
    state.tickets[tiid] = ticket
  },
  /**
   * 利用済み回数券情報のセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} ticket 回数券のオブジェクト情報
   */
  setUsedTicket: (state, ticket) => {
    state.usedTickets.push(ticket)
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}
const actions = {
  /**
   * 発行済の全ての回数券情報の取得
   */
  getTickets: async ({ commit }) => {
    try {
      const snapshot = await firestore
        .collection('tickets')
        .get()

      snapshot.forEach(doc => {
        const ticket = Object.assign(doc.data(), { tiid: doc.id })

        // stateに格納
        commit('setTicket', { tiid: doc.id, ticket: ticket })
        if (ticket.usedAt) commit('setUsedTicket', ticket)
      })
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // ドキュメントID
    siid: null,
    // 招待機能を利用するか
    used: false,
    // 入会できるユーザを招待限定とするか
    limited: false
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Boolean} 招待機能設定情報が存在するか
   */
  existSetting: state => Boolean(state.siid),
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {String} ドキュメントID
   */
  siid: state => state.siid,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Boolean} 招待機能を利用するか
   */
  used: state => state.used,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Boolean} 入会できるユーザを招待限定とするか
   */
  limited: state => state.limited
}

const mutations = {
  /**
   * 招待機能設定情報をセットする
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload セットされる引数の情報
   * @param {String} payload.siid ドキュメントID
   * @param {Boolean} payload.used 招待機能をできるか
   * @param {Boolean} payload.limited 入会できるユーザを招待限定とするか
   */
  setInvitationSetting: (state, payload) => {
    state = Object.assign(state, payload)
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 招待機能設定情報を取得する
   */
  getInvitationSetting: async ({ commit }) => {
    try {
      const snapshot = await firestore
        .collection('setting_invitation')
        .orderBy('createdAt', 'desc')
        .limit(1)
        .get()

      const doc =snapshot.empty ? null : snapshot.docs[0]
      if (doc) commit('setInvitationSetting', Object.assign({}, { siid: doc.id, used: doc.data().used, limited: doc.data().limited }))
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 招待機能設定情報を新しく追加する
   * @param {Object} params 追加する招待機能設定
   * params: { avaliable: true or false(利用するかどうか), limited: true or false(招待ユーザ限定とするか), createdAt: 設定日のtimestamp, updatedAt: 設定日のtimestamp }
   */
  addInvitationSetting: async ({ commit }, params) => {
    try {
      const ref = await firestore.collection('setting_invitation').add(params)
      commit('setInvitationSetting', Object.assign({}, { siid: ref.id, used: params.used, limited: params.limited }))
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 既存の招待機能設定情報を更新する
   * @param {Object} payload 更新する招待機能設定情報
   * payload: { siid: "更新対象のドキュメントID", params: { avaliable: true or false(利用するかどうか), limited: true or false(招待ユーザ限定とするか), updatedAt: 最終更新日のtimestamp } }
   */
  updateInvitationSetting: async ({ commit }, payload) => {
    try {
      await firestore.collection('setting_invitation').doc(payload.siid).update(payload.params)
      commit('setInvitationSetting', Object.assign({}, { siid: payload.sgid, used: payload.params.used, limited: payload.params.limited }))
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // { icid(クライアントごとのID): { name: 招待先(クライアント)の名前, description: 招待先(クライアント)の説明,kind: 招待コードの種別,codeQuantity: 招待コードの総数,billingTiming: 課金タイミング,billingStartAt: 課金開始日時,billingStartDays: 課金開始までの日数,availableEndAt: 有効期限となる日時,isDeleated: 管理者によって無効にされたか,createdAt: 作成日時,updatedAt: 最終更新日時} }
    clients: {},
    // 全件取得済みかどうか
    hasGot: false
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Boolean} クライアント情報を全件取得済みであるか
   */
  hasGot: state => state.hasGot,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {String[]} 取得済みクライアントのicidの配列
   */
  clientIDs: state => Object.keys(state.clients),
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} icid クライアントID
   * @return {Object} icidに紐づくクライアント情報
   */
  client: state => icid => state.clients[icid] || null
}

const mutations = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload 追加するクライアント(1つ)
   */
  setClient (state, payload) {
    state.clients = Object.assign({}, state.clients, payload)
  },
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Boolean} hasGot 全件取得済みであるか
   */
  setHasGot (state, hasGot) {
    state.hasGot = hasGot
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * クライアント一覧を取得する
   */
  async getClients ({ commit }) {
    try {
      const snapshot = await firestore
        .collection('invitation_clients')
        .orderBy('updatedAt', 'desc')
        .get()

      snapshot.forEach(doc => {
        commit('setClient', { [doc.id]: doc.data() })
      })
      commit('setHasGot', true)
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 指定したクライアントを取得する
   * @param {String} icid クライントID
   */
  async getClient ({ commit }, icid) {
    try {
      const doc = await firestore
        .collection('invitation_clients')
        .doc(icid)
        .get()
      if (doc.exists) commit('setClient', { [icid]: doc.data() })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 新しいクライアントを追加する
   * @param {Object} params 追加するクライアント情報 { name: 招待先(クライアント)の名前, description: 招待先(クライアント)の説明, kind: 招待コードの種別, codeQuantity: 招待コードの総数, billingTiming: 課金タイミング, billingStartAt: 課金開始日時, billingStartDays: 課金開始までの日数, availableEndAt: 有効期限となる日時, isDeleated: 管理者によって無効にされたか, createdAt: 作成日時, updatedAt: 作成日時}
   * @return {String} ドキュメントID
   */
  async addClient ({ commit }, params) {
    try {
      const ref = await firestore.collection('invitation_clients').add(params)
      const icid = ref.id
      commit('setClient', { [icid]: params })
      return icid
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

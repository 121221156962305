import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 基本情報一覧
    // { type: {}, type: {}, ... }
    settingGeneral: {}
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} type 基本情報の種類
   * @return {Object} 基本情報
   */
  settingGeneral: state => type => state.settingGeneral[type] || null
}

const mutations = {
  /**
   * 基本情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload 引数の情報
   * @param {String} payload.type 基本情報の種類
   * @param {Object} payload.settingGeneral 基本情報
   */
  setSettingGeneral: (state, payload) => {
    state.settingGeneral = Object.assign({}, state.settingGeneral, { [payload.type]: payload.settingGeneral })
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 指定種類の基本情報を取得
   * @param {String} type 基本情報の種類
   */
  getSettingGeneral: async ({ commit }, type) => {
    try {
      const snapshot = await firestore
        .collection('setting_general')
        .where('type', '==', type)
        .limit(1)
        .get()

      const doc = snapshot.empty ? null : snapshot.docs[0]
      if (doc) commit('setSettingGeneral', { type: type, settingGeneral: Object.assign(doc.data(), { sgid: doc.id }) })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 基本情報を追加
   * @param {Object} params 引数
   */
  addSettingGeneral: async ({ commit }, params) => {
    try {
      const ref = await firestore.collection('setting_general').add(params)
      commit('setSettingGeneral', { type: params.type, settingGeneral: Object.assign(params, { sgid: ref.id }) })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 基本情報を更新
   * @param {Object} payload 引数
   * @param {String} payload.sgid 基本情報のドキュメントID
   * @param {Object} payload.params 更新内容
   * @return {void}
   */
  updateSettingGeneral: async ({ commit }, payload) => {
    try {
      await firestore.collection('setting_general').doc(payload.sgid).update(payload.params)

      commit('setSettingGeneral', { type: payload.params.type, settingGeneral: Object.assign(payload.params, { sgid: payload.sgid }) })
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // { icid(クライアントごとのID): [ { code: 招待コード,  available: 利用可否, createdAt: 作成日時, updatedAt: 更新日時 },... ], {...} }
    clientCodes: {},
    // すべての招待コード一覧(発行時の重複チェック)
    allCodes: new Set()
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Boolean} 招待コード一覧の中身が空かどうか
   */
  isEmptyAllCodes: state => state.allCodes.size === 0,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} icid 招待コードを取得したいクライアントID
   * @return {Object[]} 指定したクライアントに紐づく招待コード一覧
   */
  clientCodes: state => icid => state.clientCodes[icid],
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} code 重複チェック対象の招待コード(整数文字列)
   * @return {Boolean} 重複している: true, 重複していない: false
   */
  duplicationCode: state => code => state.allCodes.has(code)
}

const mutations = {
  /**
   * クライアントに紐づく招待コード一覧をセットする
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload クライアントに紐づく招待コード情報
   * @param {Object} payload.icid クライアントID
   * @param {Object[]} payload.codes クライアントに紐づく招待コード一覧
   */
  setClientCodes (state, payload) {
    state.clientCodes[payload.icid] = payload.codes
  },
  /**
   * クライアントに紐づく招待コード情報をセットする
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload クライアントに紐づく招待コード情報
   * @param {Object} payload.icid クライアントID
   * @param {Object} payload.invitationCode クライアントに紐づく招待コード
   */
  setClientCode (state, payload) {
    if (state.clientCodes[payload.icid]) {
      state.clientCodes[payload.icid].push(payload.invitationCode)
    } else {
      state.clientCodes[payload.icid] = [payload.invitationCode]
    }
  },
  /**
   * 全招待コード一覧に招待コードを追加する
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} code 招待コード(整数文字列)
   */
  addCode (state, code) {
    state.allCodes.add(code)
  }
}

const actions = {
  /**
   * 現在発行されているすべての招待コードを取得する
   */
  async getAllCodes ({ commit }) {
    try {
      const snapshot = await firestore
        .collectionGroup('invitation_codes')
        .get()
      const docs = snapshot.size > 0 ? snapshot.docs : []
      docs.forEach(doc => {
        commit('addCode', doc.data().code)
      })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * クライアントに紐づく招待コード一覧を取得する
   * @param {String} icid クライアントID
   */
  async getClientCodes ({ commit }, icid) {
    try {
      const snapshot = await firestore
        .collection('invitation_clients')
        .doc(icid)
        .collection('invitation_codes')
        .get()
      const docs = snapshot.size > 0 ? snapshot.docs : []
      docs.forEach(doc => {
        commit('setClientCode', { icid: icid, invitationCode: doc.data() })
      })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 招待コードを追加する
   * @param {String} icid クライアントID
   * @param {Object} params 招待コードに付随する情報 { code: 招待コードの値(整数文字列), available: 利用可能かどうか(追加時はtrue), createdAt: 作成日時, updatedAt: 作成日時}
   */
  async addCode ({ commit }, { icid, params }) {
    try {
      await firestore
        .collection('invitation_clients')
        .doc(icid)
        .collection('invitation_codes')
        .add(params)
      commit('addCode', params.code)
      commit('setClientCode', { icid: icid, invitationCode: params })
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

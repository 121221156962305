import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 最新の回数券の設定情報
    settingTicket: null
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 最新の回数券情報
   */
  settingTicket: state => state.settingTicket
}

const mutations = {
  /**
   * 回数券の最新の設定情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} settingTicket 設定情報
   */
  setSettingTicket: (state, settingTicket) => {
    state.settingTicket = settingTicket
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 回数券の設定情報を作成
   * @param {Object} params 設定パラメータ
   * @return {String} 設定情報のドキュメントID
   */
  addSettingTicket: async ({ commit }, params) => {
    try {
      const doc = await firestore
        .collection('setting_tickets')
        .add(params)

      // 情報をクリアにするためにリセット
      commit('resetState')

      return doc.id
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 最新の回数券の設定情報を取得
   */
  getSettingTicket: async ({ commit }) => {
    try {
      const snapshot = await firestore
        .collection('setting_tickets')
        .orderBy('createdAt', 'desc')
        .limit(1)
        .get()

      const doc = snapshot.docs[0]
      if (doc) commit('setSettingTicket', Object.assign(doc.data(), { stid: doc.id }))
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 回数券の設定情報を更新
   * @param {String} stid 更新するドキュメントのID
   * @param {Object} params 設定パラメータ
   * @return {String} 設定情報のドキュメントID
   */
  updateSettingTicket: async ({ commit }, { stid, params }) => {
    try {
      await firestore
        .collection('setting_tickets')
        .doc(stid)
        .update(params)

      // 情報をクリアにするためにリセット
      commit('resetState')

      return stid
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

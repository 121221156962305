import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 最新の動画関係の設定情報
    // { type: {}, type: {}, ...}
    settingMovie: {}
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} type 動画情報の種類
   * @return {Object} 最新の動画関係情報
   */
  settingMovie: state => type => state.settingMovie[type] || null
}

const mutations = {
  /**
   * 動画関係の最新の設定情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} type 動画情報の種類
   * @param {Object} settingMovie 動画の設定情報
   */
  setSettingMovie: (state, { type, settingMovie }) => {
    state.settingMovie = Object.assign({}, state.settingMovie, { [type]: settingMovie })
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 動画関係の設定情報を作成
   * @param {Object} params 設定パラメータ
   * @return {String} 設定情報のドキュメントID
   */
  addSettingMovie: async ({ commit }, params) => {
    try {
      await firestore
        .collection('setting_movies')
        .add(params)

      // 情報をクリアにするためにリセット
      commit('resetState')
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 最新の動画関係の設定情報を取得
   * @param {String} type 動画の種類
   */
  getSettingMovie: async ({ commit }, type) => {
    try {
      const snapshot = await firestore
        .collection('setting_movies')
        .where('type', '==', type)
        .limit(1)
        .get()

      const doc = snapshot.docs[0]
      if (doc) commit('setSettingMovie', { type: type, settingMovie: Object.assign(doc.data(), { smid: doc.id }) })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 動画関係の設定情報を更新
   * @param {String} smid 更新するドキュメントのID
   * @param {Object} params 設定パラメータ
   * @return {String} 設定情報のドキュメントID
   */
  updateSettingMovie: async ({ commit }, { smid, params }) => {
    try {
      await firestore
        .collection('setting_movies')
        .doc(smid)
        .update(params)

      // 情報をクリアにするためにリセット
      commit('resetState')
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 動画関係情報の削除
   * @param {String} smid 削除する動画関係情報のID
   */
  deleteSettingMovie: async ({ commit }, smid) => {
    try {
      await firestore
        .collection('setting_movies')
        .doc(smid)
        .delete()

      // 情報をクリアにするためにリセット
      commit('resetState')
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

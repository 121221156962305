import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 代表者情報一覧
    // { type: {}, type: {}, ... }
    settingRepresentatives: {}
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} type 代表者情報の種類
   * @return {Object} 代表者情報
   */
  settingRepresentative: state => type => state.settingRepresentatives[type] || null
}

const mutations = {
  /**
   * 代表者情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload 引数の情報
   * @param {String} payload.type 代表者情報の種類
   * @param {Object} payload.settingRepresentative 代表者の設定情報
   */
  setSettingRepresentative: (state, payload) => {
    state.settingRepresentatives = Object.assign({}, state.settingRepresentatives, { [payload.type]: payload.settingRepresentative })
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 指定種類の代表者情報を取得
   * @param {String} type 代表者情報の種類
   */
  getSettingRepresentative: async ({ commit }, type) => {
    try {
      const snapshot = await firestore
        .collection('setting_representatives')
        .where('type', '==', type)
        .limit(1)
        .get()

      snapshot.forEach(doc => {
        commit('setSettingRepresentative', { type: type, settingRepresentative: Object.assign(doc.data(), { srid: doc.id }) })
      })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 代表者情報を追加
   * @param {Object} params 引数
   */
  addSettingRepresentative: async ({ commit }, params) => {
    try {
      const ref = await firestore.collection('setting_representatives').add(params)
      commit('setSettingRepresentative', { type: params.type, settingRepresentative: Object.assign(params, { srid: ref.id }) })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 代表者情報を更新
   * @param {Object} payload 引数
   * @param {String} payload.srid 代表者情報のドキュメントID
   * @param {Object} payload.params 更新内容
   * @return {void}
   */
  updateSettingRepresentative: async ({ commit }, payload) => {
    try {
      await firestore.collection('setting_representatives').doc(payload.srid).update(payload.params)

      commit('setSettingRepresentative', { type: payload.params.type, settingRepresentative: Object.assign(payload.params, { srid: payload.srid }) })
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

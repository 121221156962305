import { firebase, provider } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    uid: null
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {String} ユーザーID
   */
  uid: state => state.uid
}

const mutations = {
  /**
    * ユーザーIDをstateにセット
    * @param {Object} state 暗黙的に受け取るstate
    * @param {String} uid ユーザーID
    */
  setUID: (state, uid) => {
    state.uid = uid
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
    * ユーザ情報が更新時、uidを更新する
    */
  onAuth ({ commit, getters, rootGetters, dispatch }) {
    firebase.auth().onAuthStateChanged(async auth => {
      commit('setAuthProcessing', true, { root: true })

      // auth情報をセット
      commit('setUID', auth ? auth.uid : null)

      if (auth) {
        // アカウント作成で必要な情報の取得
        const isSignupUser = sessionStorage.getItem('isSignupUser')
        const signupUser = isSignupUser ?
          JSON.parse(sessionStorage.getItem('signupUser'), (key, val) => {
            // Date型はJSON.stringifyするとISO8601のStringになってしまうので、Date型に直す
            // @see https://oscdis.hatenablog.com/entry/2014/03/19/082015
            if (typeof(val) === 'string' && val.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/)) {
              return new Date(Date.parse(val))
            }
            return val
          }) : null

        // ユーザー情報の取得
        const user = await dispatch('users/getUser', auth.uid, { root: true })

        // 権限の確認
        if (isSignupUser) {
          if (!user) {
            // ユーザー情報の作成
            await dispatch('users/setUser', { uid: auth.uid, params: signupUser }, { root: true })

            // カスタムクレイムの追加
            await dispatch('functions/setCustomClaims', { uid: auth.uid, params: { admin: true } }, { root: true })

            // 例外情報のタグに「管理者」を追加
            const taid = await dispatch('tags/addTag', { name: '管理者', createdAt: new Date(), updatedAt: new Date() }, { root: true })

            // 例外情報の作成
            await dispatch('exemptions/addExemption', {
              uid: auth.uid,
              email: auth.email,
              taid: taid,
              registratedAt: new Date(),
              createdAt: new Date(),
              updatedAt: new Date()
            }, { root: true })


            // 必要な情報の取得
            await dispatch('auth/getRequiredInfo', null, { root: true })

            commit('setTelop', { show: true, msg: '管理者として追加しました', type: 'success' }, { root: true })
            commit('setRedirectURL', '/', { root: true })
          } else {
            // 既にユーザー情報がある場合はログアウト
            await dispatch('signout')
            commit('setTelop', { show: true, msg: 'アカウントが存在します', type: 'error' }, { root: true })
            commit('setRedirectURL', '/error', { root: true })
          }
          // セッション情報の削除
          sessionStorage.removeItem('isSignupUser')
          sessionStorage.removeItem('signupUser')
        } else if (!user || user.authority !== 'admin') {
          // ユーザーが存在しない or 権限がない場合はログアウトして警告
          await dispatch('signout')
          commit('setTelop', { show: true, msg: 'アクセス権限がありません', type: 'error' }, { root: true })
          commit('setRedirectURL', '/login', { root: true })
        } else {
          // 必要な情報の取得
          await dispatch('auth/getRequiredInfo', null, { root: true })

          // サービスが初回公開前な場合、基本情報を更新することを促す
          const isFirstPublished = rootGetters['stops/isFirstPublished']
          if (isFirstPublished) commit('setTelop', { show: true, msg: '基本情報を更新してサービスを公開しよう', type: 'warning' }, { root: true })
        }
      }

      commit('setAuthProcessing', false, { root: true })

      // 初回アクセスの場合にリダイレクトさせ、routerを反応させる
      const redirectPath = rootGetters.redirectPath
      router.replace({ path: redirectPath, query: { auth: new Date().getTime() } })
    })
  },
  /**
   * 初回アクセス時に全体で必要な情報を取得する
   */
  getRequiredInfo: async ({ commit, dispatch }) => {
    try {
      await Promise.all([
        dispatch('stops/isFirstPublished', null, { root: true })
      ])
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * サインイン
   * @param {String} sns SNS認証のプロバイダー名 google, facebook, twitter
   */
  signin: ({ commit }, sns) => {
    try {
      firebase.auth().signInWithRedirect(provider[sns])
      return
    } catch {
      // エラーの場合はエラー画面に遷移させる
      router.push({ name: 'error' })
    }
  },
  /**
   * サインアウト
   * @return {Object} status：成功の有無（success or error）、error：エラーコード（エラー時のみ）
   */
  signout: async ({ commit, dispatch }) => {
    try {
      await firebase.auth().signOut()

      // storeの不要な情報をリセットする
      dispatch('resetState', null, { root: true })

      return { status: 'success' }
    } catch (error) {
      return { status: 'error', error: error.code }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

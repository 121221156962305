import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home.vue'

import store from '@/store'
import { firebase } from '@/firebase'

/**
 * vue-router v3.1.0から、同一画面に遷移するとコンソールエラーが発生するようになった
 * push関数にエラーハンドリングを追加して、デフォルトの関数を上書きすることで対応
 * @see https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
 */
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = Router.prototype.replace
Router.prototype.replace = function replace (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: { requiresAuth: true }
    },
    {
      path: '/login',
      name: 'login',
      component: loadComponent('Login.vue')
    },
    {
      path: '/admin',
      name: 'admin',
      component: loadComponent('Admin.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/admin/add',
      name: 'admin_add',
      component: loadComponent('AdminAdd.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/admin/delete',
      name: 'admin_delete',
      component: loadComponent('AdminDelete.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/member',
      name: 'member',
      component: loadComponent('Member.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/member/new',
      name: 'member_new',
      component: loadComponent('MemberNew.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/member/cancel',
      name: 'member_cancel',
      component: loadComponent('MemberCancel.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/member/list',
      name: 'member_list',
      component: loadComponent('MemberList.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/exception',
      name: 'exception',
      component: loadComponent('Exception.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/exception/add',
      name: 'exception_add',
      component: loadComponent('ExceptionAdd.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/exception/edit',
      name: 'exception_edit',
      component: loadComponent('ExceptionEdit.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/exception/tag',
      name: 'exception_tag',
      component: loadComponent('ExceptionTag.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/invitation',
      name: 'invitation',
      component: loadComponent('Invitation.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/invitation/setting',
      name: 'invitation_setting',
      component: loadComponent('InvitationSetting.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/invitation/create',
      name: 'invitation_create',
      component: loadComponent('InvitationCreate.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/invitation/clients',
      name: 'invitation_clients',
      component: loadComponent('InvitationClients.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/invitation/clients/:icid',
      name: 'invitation_clients_detail',
      component: loadComponent('InvitationClientsDetail.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/group',
      name: 'group',
      component: loadComponent('Group.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/group/setting',
      name: 'group_setting',
      component: loadComponent('GroupSetting.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/ticket',
      name: 'ticket',
      component: loadComponent('Ticket.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/ticket/setting',
      name: 'ticket_setting',
      component: loadComponent('TicketSetting.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/ticket/histories',
      name: 'ticket_histories',
      component: loadComponent('TicketHistories.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/setting',
      name: 'setting',
      component: loadComponent('Setting.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/setting/general',
      name: 'setting_general',
      component: loadComponent('SettingGeneral.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/setting/signup',
      name: 'setting_signup',
      component: loadComponent('SettingSignup.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/setting/registrator',
      name: 'setting_registrator',
      component: loadComponent('SettingRegistrator.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/setting/representative',
      name: 'setting_representative',
      component: loadComponent('SettingRepresentative.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/setting/movie',
      name: 'setting_movie',
      component: loadComponent('SettingMovie.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/setting/display',
      name: 'setting_display',
      component: loadComponent('SettingDisplay.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/setting/image',
      name: 'setting_image',
      component: loadComponent('SettingImage.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/setting/privilege',
      name: 'setting_privilege',
      component: loadComponent('SettingPrivilege.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/setting/faq',
      name: 'setting_faq',
      component: loadComponent('SettingFaq.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/setting/freetrial',
      name: 'setting_freetrial',
      component: loadComponent('SettingFreetrial.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/setting/login',
      name: 'setting_login',
      component: loadComponent('SettingLogin.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/setting/footer',
      name: 'setting_footer',
      component: loadComponent('SettingFooter.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/request',
      name: 'request',
      component: loadComponent('Request.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '/error',
      name: 'error',
      component: loadComponent('Error.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '/notfound',
      name: 'notfound',
      component: loadComponent('NotFound.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '**',
      redirect: { name: 'notfound' }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

/**
 * viewsのファイルをロード
 * @param {String} name viewsのファイル名
 * @return {Object} 遅延ロードしたコンポーネント
 */
function loadComponent (name) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${name}`)
}

/**
 * ページ遷移の分岐処理
 */
router.beforeEach((to, from, next) => {
  const autoTransition = to.matched.some(record => record.meta.autoTransition)
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  // 認証後の遷移先として現在のURLを保存する
  const redirectURL = store.getters.redirectPath
  if (!redirectURL) store.commit('setRedirectURL', to.path)

  // 判定用のデータ取得
  const auth = firebase.auth().currentUser
  const uid = auth ? auth.uid : null
  const isAuthProcessing = store.getters.isAuthProcessing

  // 認証、ユーザー取得状況に応じてページ遷移
  // 初回アクセス時、onAuthの処理が終わる前にrouterが動くため、初回処理が終わるまで遷移をループさせる
  if (isAuthProcessing || autoTransition) {
    // Auth処理中の場合は遷移をキャンセルさせる
    autoTransition ? next() : next(false)
  } else if (!uid) {
    // 未ログインの場合
    requiresAuth ? next({ name: 'login' }) : next()
  } else {
    // ログイン済みの場合
    requiresAuth ? next() : next({ name: 'home' })
  }
})

/**
 * beforeEachで遷移が確定した後の処理
 */
router.beforeResolve((to, from, next) => {
  const isAuthProcessing = store.getters.isAuthProcessing

  // 遷移先と遷移元が異なればisProcessingをtrueにする
  if (!isAuthProcessing && to.name !== from.name) store.commit('setProcessing', true)
  next()
})

export default router

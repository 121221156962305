import Vue from 'vue'
import Vuex from 'vuex'

import auth from '@/store/modules/auth'
import functions from '@/store/modules/functions'
import cloudstorage from '@/store/modules/cloudstorage'
import users from '@/store/modules/users'
import exemptions from '@/store/modules/exemptions'
import tags from '@/store/modules/tags'
import tickets from '@/store/modules/tickets'
import invitationClients from '@/store/modules/invitationClients'
import usedCount from '@/store/modules/invitation/usedCount'
import invitationCodes from '@/store/modules/invitation/invitationCodes'
import invitationHistories from '@/store/modules/invitationHistories'
import settingInvitation from '@/store/modules/settingInvitation'
import settingGeneral from '@/store/modules/settingGeneral'
import settingGroup from '@/store/modules/settingGroup'
import settingDisplayed from '@/store/modules/settingDisplayed'
import settingImages from '@/store/modules/settingImages'
import settingPrivileges from '@/store/modules/settingPrivileges'
import settingRepresentatives from '@/store/modules/settingRepresentatives'
import settingTickets from '@/store/modules/settingTickets'
import settingMovies from '@/store/modules/settingMovies'
import settingFreetrials from '@/store/modules/settingFreetrials'
import cards from '@/store/modules/payments/cards'
import faqs from '@/store/modules/faqs'
import settingFooter from '@/store/modules/settingFooter'
import settingButtons from '@/store/modules/settingButtons'
import settingSignups from '@/store/modules/settingSignups'
import stops from '@/store/modules/stops'
import loginReferrer from '@/store/modules/loginReferrer'

Vue.use(Vuex)

const modules = { auth, functions, cloudstorage, users, exemptions, tags, tickets, invitationClients, usedCount, invitationCodes, invitationHistories, settingInvitation, settingGeneral, settingGroup, settingDisplayed, settingImages, settingPrivileges, settingTickets, settingRepresentatives, settingMovies, cards, faqs, settingFooter, settingFreetrials, settingButtons, settingSignups, stops, loginReferrer }

export default new Vuex.Store({
  modules: modules,
  state: {
    isProcessing: true,
    isSubmitting: false,
    isAuthProcessing: true,
    redirectPath: null,
    telop: {
      show: false,
      msg: '',
      type: ''
    },
    emails: {},
    activeUIDs: [],
    /**
     * 招待ユーザに対する課金タイミングの種類
     * - immediate: 登録日即日
     * - specifiedDay: 指定した日付
     * - afterDays: 登録後XX日
     */
    INVITATION_BILLING_TIMING_TYPE: {
      immediate: 'immediate',
      specifiedDay: 'specifiedDay',
      afterDays: 'afterDays'
    },
    MAX_LENGTH: {
      SETTING_GENERAL: {
        serviceName: 15,
        about: 400,
        companyName: 30
      },
      SETTING_DISPLAY: {
        title: 20,
        password: 30,
        pwdTxt: 50
      },
      SETTING_REPRESENTATIVES: {
        name: 15,
        description: 400
      },
      SETTING_GROUP: {
        name: 20
      },
      SETTING_TICKETS: {
        name: 40,
        description: 400,
        usage: 400,
        warning: 400
      },
      SETTING_FAQ: {
        question: 30,
        answer: 200
      },
      SETTING_MOVIE: {
        title: 40,
        description: 400
      },
      SETTING_FOOTER: {
        name: 30
      },
      SETTING_BUTTON: {
        button: 20,
        supplementTop: 40,
        supplementBottom: 40
      },
      SETTING_SIGNUP: {
        messages: 30,
        features: 50
      },
      SETTING_INVITATION: {
        name: 40,
        description: 400
      }
    }
  },
  getters: {
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Boolean} 処理中かどうか
     */
    isProcessing: state => state.isProcessing,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Boolean} フォーム等の送信中かどうか
     */
    isSubmitting: state => state.isSubmitting,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Boolean} Auth処理中かどうか
     */
    isAuthProcessing: state => state.isAuthProcessing,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {String} 遷移先のURL
     */
    redirectPath: state => state.redirectPath,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Object} テロップの情報
     */
    telop: state => state.telop,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Object} ユーザーのメールアドレス一覧 { uid: メールアドレス, uid: メールアドレス, ... }
     */
    emails: state => state.emails,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {String[]} アクティブなユーザーのuid一覧
     */
    activeUIDs: state => state.activeUIDs,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Object} 招待ユーザに対する課金タイミングの種類
     */
    INVITATION_BILLING_TIMING_TYPE: state => state.INVITATION_BILLING_TIMING_TYPE,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Objcet} プロフィールの格納できる文字数の最大値
     */
    MAX_LENGTH: state => state.MAX_LENGTH
  },
  mutations: {
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Boolean} isAuthProcessing Auth処理中かどうか
     */
    setAuthProcessing: (state, isAuthProcessing) => {
      state.isAuthProcessing = isAuthProcessing
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Boolean} isProcessing 処理中かどうか
     */
    setProcessing: (state, isProcessing) => {
      state.isProcessing = isProcessing
      if (state.isSubmitting) state.isSubmitting = false
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Boolean} isSubmitting フォーム等の送信中かどうか
     */
    setSubmitting: (state, isSubmitting) => {
      state.isSubmitting = isSubmitting
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {String} path アクセスされたURL
     */
    setRedirectURL: (state, path) => {
      state.redirectPath = path
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Object} telop テロップの情報
     * @param {Boolean} telop.show テロップ表示の有無
     * @param {String} telop.msg テロップの内容
     * @param {String} telop.type テロップの色 success, warning, error
     */
    setTelop: (state, telop) => {
      state.telop = telop
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Object} emails メールアドレス一覧
     */
    setEmails: (state, emails) => {
      state.emails = emails
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {String[]} activeUIDs アクティブユーザーの一覧
     */
    setActiveUIDs: (state, activeUIDs) => {
      state.activeUIDs = activeUIDs
    }
  },
  actions: {
    /**
     * 全モジュールのstore情報をリセットする
     */
    resetState: ({ commit }) => {
      Object.keys(modules).forEach(key => {
        // stateにプロパティが存在するモジュールのresetStateを起動する
        if (Object.keys(modules[key].state).length !== 0) commit(key + '/resetState')
      })
    }
  }
})

import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
  // カード情報 { uid: {}, uid: {}, ... }
    cards: {}
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} カード情報
   */
  cards: state => state.cards
}

const mutations = {
  /**
   * カード情報のセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload カード情報
   * @param {String} payload.uid カード情報を持つユーザーのID
   * @param {Object} payload.card カード情報
   */
  setCard: (state, payload) => {
    state.cards = Object.assign({}, state.cards, { [payload.uid]: payload.card })
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * カード情報の取得
   * @param {String} uid ユーザーID
   */
  getCard: async ({ commit }, uid) => {
    try {
      const snapshot = await firestore
        .collection('payments')
        .doc(uid)
        .collection('cards')
        .orderBy('createdAt', 'desc')
        .limit(1)
        .get()
      snapshot.forEach(doc => {
        commit('setCard', { uid: uid, card: doc.data() })
      })
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // { `icidの値`: `countの値`, `icidの値`: `countの値`, ... }
    usedCounts: {}
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} icid クライアントID
   * @return {Number} 利用された招待コードの数
   */
  usedCount: state => icid => state.usedCounts[icid] ? state.usedCounts[icid] : 0
}

const mutations = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload 追加する利用された招待コードの数 { `icidの値`: `countの値` }
   */
  setUsedCount (state, payload) {
    state.usedCounts = Object.assign({}, state.usedCounts, payload)
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * クライアントIDから招待コードの利用数を取得する
   * @param {String} icid クライアントID
   */
  async getUsedCount ({ commit }, icid) {
    try {
      const snapshot = await firestore
        .collection('invitation_clients')
        .doc(icid)
        .collection('used_count')
        .limit(1)
        .get()
      const doc = snapshot.empty ? null : snapshot.docs[0]
      commit('setUsedCount', { [icid]: doc ? doc.data().count : 0 })
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // { sgid: "ドキュメントID", type: "groupName", value: "限定グループ名", createdAt: 設定日のtimestamp, updatedAt: 最終更新日のtimestamp }
    groupName: {},
    // { sgid: "ドキュメントID", type: "groupURL", value: "限定グループのURL", createdAt: 設定日のtimestamp, updatedAt: 最終更新日のtimestamp }
    groupURL: {},
    // { sgid: "ドキュメントID", type: "discordServerID", value: "連携済みDiscordサーバーのID", createdAt: 設定日のtimestamp, updatedAt: 最終更新日のtimestamp }
    discordServerID: {}
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 限定グループ名情報
   */
  groupName: state => state.groupName || {},
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 限定グループのURL情報
   */
  groupURL: state => state.groupURL || {},
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} DiscordサーバーID情報
   */
  discordServerID: state => state.discordServerID || {},
  /**
   * 限定グループの現在設定がされているか
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Boolean} 限定グループ設定の状態
   */
  isSetGroupSetting: state => {
    const isSetGroupName = Object.keys(state.groupName).length > 0 ? Boolean(state.groupName.value) : false
    const isSetGroupURL = Object.keys(state.groupURL).length > 0 ? Boolean(state.groupURL.value) : false
    return isSetGroupName && isSetGroupURL
  }
}

const mutations = {
  /**
   * 限定グループの設定を種類に基づいてセットする
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload セットされる限定グループの設定オブジェクト
   */
  setGroupSetting: (state, payload) => {
    state[payload.type] = payload
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * typeに紐づく限定グループの設定情報オブジェクトを取得しstoreを更新する
   * @param {String} type 限定グループの設定項目名
   */
  getGroupSetting: async ({ commit }, type) => {
    try {
      if (validatedGroupSettingType(type)) {
        const snapshot = await firestore
          .collection('setting_general')
          .where('type', '==', type)
          .limit(1)
          .get()

        const doc =snapshot.empty ? null : snapshot.docs[0]
        if (doc) commit('setGroupSetting', Object.assign(doc.data(), { sgid: doc.id }))
      } else {
        throw new Error(`${type}は限定グループの設定項目に存在しない種別です`)
      }
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 限定グループ設定情報を新しく追加する
   * @param {Object} params 追加する限定グループの設定項目
   * params: { type: "項目種類名", value: "設定する値", createdAt: 設定日のtimestamp, updatedAt: 設定日のtimestamp }
   */
  addGroupSetting: async ({ commit }, params) => {
    try {
      if (validatedGroupSettingType(params.type)) {
        const ref = await firestore.collection('setting_general').add(params)
        commit('setGroupSetting', Object.assign(params, { sgid: ref.id }))
      } else {
        throw new Error(`${params.type}は限定グループの設定項目に存在しない種別です`)
      }
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 既存の限定グループの設定情報を更新する
   * @param {Object} payload 更新する限定グループの設定情報
   * payload: { sgid: "更新対象のドキュメントID", params: { type: "項目種類名", value: "設定する値", updatedAt: 最終更新日のtimestamp } }
   */
  updateGroupSetting: async ({ commit }, payload) => {
    try {
      if (validatedGroupSettingType(payload.params.type)) {
        await firestore.collection('setting_general').doc(payload.sgid).update(payload.params)
        commit('setGroupSetting', Object.assign(payload.params, { sgid: payload.sgid }))
      } else {
        throw new Error(`${payload.params.type}は限定グループの設定項目に存在しない種別です`)
      }
    } catch {
      router.push({ name: 'error' })
    }
  }
}

/**
 * 指定された項目が限定グループの設定項目に含まれているかをチェックする
 * @param {String} type 限定グループの設定項目名
 * @return {Boolean} 含まれているかどうか
 */
const validatedGroupSettingType = type => {
  return Object.keys(state).includes(type)
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // フッター情報の一覧（rank順）
    settingFooters: []
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} フッター情報の一覧（rank順）
   */
  settingFooters: state => state.settingFooters
}

const mutations = {
  /**
   * フッター情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} newFooterInfo フッター情報のオブジェクト情報
   */
  setSettingFooter: (state, newFooterInfo) => {
    const index = state.settingFooters.findIndex(footerInfo => footerInfo.sfid === newFooterInfo.sfid)
    if (index > -1) {
      // 既にフッター情報がある場合は更新
      Object.keys(newFooterInfo).forEach(key => {
        state.settingFooters[index][key] = newFooterInfo[key]
      })
    } else {
      // 新規追加の場合
      state.settingFooters.push(newFooterInfo)
    }
  },
  /**
   * フッター情報をstateから削除
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} sfid 削除するフッター情報のID
   */
  deleteSettingFooter: (state, sfid) => {
    state.settingFooters = state.settingFooters.filter(footerInfo => footerInfo.sfid !== sfid)
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * フッター情報の一覧を取得
   */
  getSettingFooter: async ({ commit }) => {
    try {
      const snapshot = await firestore
        .collection('setting_footer')
        .orderBy('rank', 'asc')
        .get()

      // 取得した値をstoreに格納
      snapshot.docs.forEach(doc => commit('setSettingFooter', Object.assign({ sfid: doc.id }, doc.data())))
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * フッター情報の作成
   * @param {Object} params フッター情報
   */
  addSettingFooter: async ({ commit }, params) => {
    try {
      const ref = await firestore.collection('setting_footer').add(params)
      commit('setSettingFooter', Object.assign(params, { sfid: ref.id }))
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * フッター情報の更新
   * @param {String} sfid 更新するフッター情報のID
   * @param {Object} params 更新するフッター情報
   */
  updateSettingFooter: async ({ commit }, { sfid, params }) => {
    try {
      await firestore.collection('setting_footer').doc(sfid).update(params)
      commit('setSettingFooter', Object.assign(params, { sfid: sfid }))
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * フッター情報の削除
   * @param {String} sfid 削除するフッター情報のID
   */
  deleteSettingFooter: async ({ commit }, sfid) => {
    try {
      await firestore.collection('setting_footer').doc(sfid).delete()
      commit('deleteSettingFooter', sfid)
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 表示情報一覧
    settingDisplayed: []
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 表示有無情報
   */
  settingDisplayed: state => state.settingDisplayed,
  /**
   * 指定した種別の表示情報を取得する 複数存在する場合はindexの小さいものを取得する
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} type 種別名
   * @return {Object} 表示情報
   */
  settingDisplayedByType: state => type => state.settingDisplayed.find(setting => setting.type === type)
}

const mutations = {
  /**
   * 表示情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} settingDisplayed 表示情報
   */
  setSettingDisplayed: (state, settingDisplayed) => {
    state.settingDisplayed.push(settingDisplayed)
  },
  /**
   * 表示情報の更新
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} sdid ドキュメントID
   * @param {Object} params 更新する値
   */
  updateSettingDisplayed: (state, { sdid, params }) => {
    const index = state.settingDisplayed.findIndex(setting => setting.sdid === sdid)
    Object.keys(params).forEach(key => {
      state.settingDisplayed[index][key] = params[key]
    })
  },
  /**
   * 表示情報の削除
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} sdid ドキュメントID
   */
  deleteSettingDisplayed: (state, sdid) => {
    state.settingDisplayed = state.settingDisplayed.filter(setting => setting.sdid !== sdid)
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 表示情報を取得
   */
  getSettingDisplayed: async ({ commit }) => {
    try {
      const snapshot = await firestore
        .collection('setting_displayed')
        .orderBy('createdAt', 'desc')
        .get()

      snapshot.forEach(doc => {
        commit('setSettingDisplayed', Object.assign({ sdid: doc.id }, doc.data()))
      })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 表示有無情報を追加
   * @param {Object} params 引数
   */
  addSettingDisplayed: async ({ commit }, params) => {
    try {
      const ref = await firestore.collection('setting_displayed').add(params)
      commit('setSettingDisplayed', Object.assign(params, { sdid: ref.id }))
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 表示情報を更新
   * @param {String} sdid 表示情報のドキュメントID
   * @param {Object} params 更新内容
   * @return {void}
   */
  updateSettingDisplayed: async ({ commit }, { sdid, params }) => {
    try {
      await firestore.collection('setting_displayed').doc(sdid).update(params)

      commit('updateSettingDisplayed', { sdid: sdid, params: params })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 表示情報の削除
   * @param {String} sdid 表示情報のドキュメントID
   * @return {void}
   */
  deleteSettingDisplayed: async ({ commit }, sdid) => {
    try {
      await firestore.collection('setting_displayed').doc(sdid).delete()

      commit('deleteSettingDisplayed', sdid)
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 最新の無料トライアル情報
    settingFreetrial: null
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 無料トライアル情報
   */
  settingFreetrial: state => state.settingFreetrial
}

const mutations = {
  /**
   * 無料トライアル情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} freetrial 無料トライアル情報
   */
  setSettingFreetrial: (state, freetrial) => {
    state.settingFreetrial = freetrial
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 最新の無料トライアル情報を取得
   */
  getSettingFreetrial: async ({ commit }) => {
    try {
      const snapshot = await firestore
        .collection('setting_freetrials')
        .orderBy('createdAt', 'desc')
        .limit(1)
        .get()

      const doc = snapshot.empty ? null : snapshot.docs[0]
      if (doc) commit('setSettingFreetrial', Object.assign(doc.data(), { sftid: doc.id }))
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 無料トライアル情報を作成
   * @param {Object} params 作成する値
   */
  addSettingFreetrial: async ({ commit }, params) => {
    try {
      const ref = await firestore.collection('setting_freetrials').add(params)
      commit('setSettingFreetrial', Object.assign(params, { sftid: ref.id }))
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 無料トライアル情報の更新
   * @param {String} sftid 更新するドキュメントID
   * @param {Object} params 作成する値
   */
  updateSettingFreetrial: async ({ commit }, { sftid, params }) => {
    try {
      await firestore.collection('setting_freetrials').doc(sftid).update(params)

      // 情報をクリアにするためにリセット
      commit('resetState')
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
